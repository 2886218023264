.FlexCC {
    display: flex;
    align-items: center;
    justify-content: center;
}

.FlexBar {
    display: flex;
}

.Flex-Sb {
    display: flex;
    justify-content: space-between;
}

.FlexRSA {
    display: flex;
    justify-content: space-around;
}

.FlexCR {
    display: flex;
    flex-direction: column-reverse;
}

.FlexFCO {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.Flex-Sb-Ce {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FlexPuls {
    display: flex;
    flex-wrap: wrap;
}

.Flex-Wr-Jc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Flex-Wr-Sb {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.FlexVC {
    display: flex;
    align-items: center;
}

.FlexSC {
    display: flex;
    justify-content: center;
}

.FlexVSB {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FlexVSA {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.Flex-Fe {
    display: flex;
    justify-content: flex-end;
}